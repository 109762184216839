/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable plugin/no-unsupported-browser-features */
/* stylelint-enable plugin/no-unsupported-browser-features */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1mabk_3450l_99:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-pa3mqb, 14px);
  line-height: var(--line-height-body-m-2zx78l, 22px);
  color: var(--color-text-body-default-ffdwgg, #16191f);
  font-weight: 400;
  font-family: var(--font-family-base-4om3hr, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
}

.awsui_radio_1mabk_3450l_135:not(#\9) {
  /*used in test-utils*/
}

.awsui_radio_1mabk_3450l_135 + .awsui_radio_1mabk_3450l_135:not(#\9) {
  margin-block-start: var(--space-scaled-xxs-t7ij38, 4px);
}

.awsui_radio--has-description_1mabk_3450l_143 + .awsui_radio_1mabk_3450l_135:not(#\9) {
  margin-block-start: var(--space-scaled-xs-x91h81, 8px);
}

.awsui_radio-control_1mabk_3450l_147:not(#\9) {
  margin-block-start: calc((var(--line-height-body-m-2zx78l, 22px) - var(--size-control-73ulj5, 14px)) / 2);
  min-block-size: var(--size-control-73ulj5, 14px);
  min-inline-size: var(--size-control-73ulj5, 14px);
  block-size: var(--size-control-73ulj5, 14px);
  inline-size: var(--size-control-73ulj5, 14px);
}

.awsui_outline_1mabk_3450l_155:not(#\9) {
  position: relative;
}
.awsui_outline_1mabk_3450l_155:not(#\9) {
  outline: 2px dotted transparent;
  outline-offset: calc(2px - 1px);
}
.awsui_outline_1mabk_3450l_155:not(#\9)::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 2px);
  inset-block-start: calc(-1 * 2px);
  inline-size: calc(100% + 2 * 2px);
  block-size: calc(100% + 2 * 2px);
  border-start-start-radius: var(--border-radius-control-circular-focus-ring-mxepal, 50%);
  border-start-end-radius: var(--border-radius-control-circular-focus-ring-mxepal, 50%);
  border-end-start-radius: var(--border-radius-control-circular-focus-ring-mxepal, 50%);
  border-end-end-radius: var(--border-radius-control-circular-focus-ring-mxepal, 50%);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-v8nq2m, #0073bb);
}

.awsui_styled-circle-border_1mabk_3450l_177:not(#\9) {
  stroke: var(--color-border-control-default-8uhjl0, #687078);
  fill: var(--color-background-control-default-n6yadr, #ffffff);
}
.awsui_styled-circle-border_1mabk_3450l_177.awsui_styled-circle-disabled_1mabk_3450l_181:not(#\9) {
  fill: var(--color-background-control-disabled-dsn0t2, #d5dbdb);
  stroke: var(--color-background-control-disabled-dsn0t2, #d5dbdb);
}

.awsui_styled-circle-fill_1mabk_3450l_186:not(#\9) {
  stroke: var(--color-background-control-checked-pvanxo, #0073bb);
  fill: var(--color-foreground-control-default-8qoldl, #ffffff);
  opacity: 0;
  transition: opacity var(--motion-duration-transition-quick-j01tkg, 90ms) var(--motion-easing-transition-quick-9ktw66, linear);
}
.awsui_styled-circle-fill_1mabk_3450l_186.awsui_styled-circle-checked_1mabk_3450l_192:not(#\9) {
  opacity: 1;
}
.awsui_styled-circle-fill_1mabk_3450l_186.awsui_styled-circle-disabled_1mabk_3450l_181:not(#\9) {
  fill: var(--color-foreground-control-disabled-fcoqdk, #ffffff);
  stroke: var(--color-background-control-disabled-dsn0t2, #d5dbdb);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_styled-circle-fill_1mabk_3450l_186:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_styled-circle-fill_1mabk_3450l_186:not(#\9), .awsui-mode-entering .awsui_styled-circle-fill_1mabk_3450l_186:not(#\9) {
  animation: none;
  transition: none;
}